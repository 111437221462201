<template>
  <breadcrumbs-component :breadCrumbs="userManagementBreadCrumbs"/>
  <div class="content-wrapper">
    <div class="wrapper">
      <!--------- USERS --------------->
      <div class="users-options">
        <UserAdministrationCreateAssessmentComponent
          v-if="getPermissions.admin"
          :getCheckedUsers="getCheckedUsers"
        />
        <div class="users-option-element" @click.prevent="deleteCheckedUsers">
          <font-awesome-icon class="user-option-icon" icon="fa-trash-can"/>
          <p>{{ $t("UserAdministrationComponent.options.deleteMarked") }}</p>
        </div>
        <UserAdministrationDownloadCSVComponent
          :getCheckedUsers="getCheckedUsers"
          :loadingDownloadCSV="loadingDownloadCSV"
          @downloadSelectedUsersCSV="downloadSelectedUsersCSV"
          @downloadAllUsersCSV="downloadAllUsersCSV"
        />
        <user-administration-filter-component
          @changeUserTableLoadingState="changeUserTableLoadingState"
        />
      </div>

      <div class="users-options selected-users-count">
        <div class="users-option-element" v-if="getCheckedUsersCount !== 0" >
          {{ $t("UserAdministrationComponent.options.selected") }}: {{ getCheckedUsersCount }}
          <div class="delete-button" @click="clearCheckedUsers">
            <font-awesome-icon icon="fa-xmark"/>
          </div>
        </div>
      </div>

      <div>
        <div v-if="users.length !== 0">
          <div class="users-grid">
            <div class="checkbox-icon-wrapper" @click.prevent="handleCheckboxChangeAllUser()">
              <font-awesome-icon
                v-if="isCheckedAllUser"
                class="checked-icon"
                icon="fa-square-check"
              />

              <font-awesome-icon v-else class="unchecked-icon" icon="fa-regular fa-square"/>
            </div>

            <div class="users-grid-columnName">
              <p>{{ $t("UserAdministrationComponent.columnNames.name") }}</p>
              <SortIconComponent :sortValue="getSortByName"
              @click="handleSortChange('sortByName', getSortByName)"/>
            </div>
            <div class="users-grid-columnName">

            <p class="level-text">
              {{ $t("UserAdministrationComponent.columnNames.level") }}
            </p>
            <SortIconComponent :sortValue="getSortByLevel"
            @click="handleSortChange('sortByLevel', getSortByLevel)"/>
            </div>
            <div class="users-grid-columnName">

            <p>{{ $t("UserAdministrationComponent.columnNames.emailAddress") }}</p>
            <SortIconComponent :sortValue="getSortByEmail"
            @click="handleSortChange('sortByEmail', getSortByEmail)"/>

            </div>
            <div class="users-grid-columnName">

            <p>{{ $t("UserAdministrationComponent.columnNames.lastActivity") }}</p>
            <SortIconComponent :sortValue="getSortByLastActivity"
            @click="handleSortChange('sortByLastActivity', getSortByLastActivity)"/>

            </div>

          </div>

          <div v-if="!loading" class="users-grid">
            <UserAdministrationTableRowProfileComponent
              v-for="user in users"
              :key="user._id"
              :user="user"
            />
            </div>

            <div v-else class="loading-container">
              <img class="loading-img" src="@/assets/loadingSpinner.svg" alt="txt"/>
            </div>

          <div class="users-per-page">
            <p>{{ $t("UserAdministrationComponent.options.usersPerPage") }}</p>
            <select class="users-per-page-dropdown" v-model="usersPerPage">
              <option v-for="option in usersPerPageOptions" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>

          <pagination-component
            :currentPage="currentPage"
            :itemsPerPage="usersPerPage"
            :items-count="getAllActiveUsersCount"
            @updateCurrentPage="updateCurrentPage"
          />
        </div>
        <div v-else class="loading-container">
          <h3>{{ $t("UserAdministrationComponent.noResults") }}</h3>
        </div>
      </div>
    </div>
  </div>

  <pop-up-confirmation-component
    v-if="showPopup"
    :message="getPopUpMessage"
    :confirmButtonText="deleteButtonText"
    :cancelButtonText="cancelButtonText"
    :confirmButtonColor="'red'"
    @cancel="onPopupCancel"
    @confirm="onPopupConfirm"
  />
</template>

<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import UserAdministrationTableRowProfileComponent
  from '@/components/Home/UserAdministration/UserAdministrationTableRowProfileComponent.vue';
import UserAdministrationFilterComponent
  from '@/components/Home/UserAdministration/UserAdministrationFilterComponent.vue';
import UserAdministrationDownloadCSVComponent
  from '@/components/Home/UserAdministration/UserAdministrationDownloadCSVComponent.vue';
import UserAdministrationCreateAssessmentComponent
  from '@/components/Home/UserAdministration/UserAdministrationCreateAssessmentComponent.vue';

import PopUpConfirmationComponent from '@/components/global/PopUpConfirmationComponent.vue';
import PaginationComponent from '@/components/Home/Shared/PaginationComponent.vue';

import NetworkAdmin from '@/helpers/networking/NetworkAdmin';
import SortIconComponent from '../Shared/SortIconComponent.vue';

export default {
  name: 'UserAdministrationComponent',
  components: {
    BreadcrumbsComponent,
    UserAdministrationTableRowProfileComponent,
    UserAdministrationFilterComponent,
    UserAdministrationDownloadCSVComponent,
    UserAdministrationCreateAssessmentComponent,
    PopUpConfirmationComponent,
    PaginationComponent,
    SortIconComponent,
  },
  data() {
    return {
      userManagementBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/user-administration',
          isActive: false,
          name: this.$t('BreadCrumbs.administration'),
        },
        {
          goTo: '/user-administration',
          isActive: true,
          name: this.$t('BreadCrumbs.useradministration'),
        },
      ],
      loading: false,
      loadingDownloadCSV: false,

      usersPerPageOptions: [2, 5, 10, 15, 20, 50, 100],

      showPopup: false,
      deleteButtonText: this.$t('UserAdministrationComponent.popup.deleteButton'),
      cancelButtonText: this.$t('UserAdministrationComponent.popup.cancelButton'),

    };
  },
  computed: {
    users() {
      return this.$store.getters.getAllActiveUsers;
    },
    getCheckedUsers() {
      return this.$store.getters.getAllCheckedUsers;
    },
    getCheckedUsersCount() {
      return this.$store.getters.getCheckedUsersCount;
    },
    getAllActiveUsersCount() {
      return this.$store.getters.getAllActiveUsersCount;
    },
    usersPerPage: {
      get() {
        return this.$store.getters.getUsersPerPage;
      },
      set(value) {
        this.$store.dispatch('updateUsersPerPage', value);
        this.$store.dispatch('uncheckAllUsers');
        this.$store.dispatch('getAllUsers');
      },
    },
    getPopUpMessage() {
      return this.$tc('UserAdministrationComponent.popup.deleteMessage', this.getCheckedUsersCount);
    },
    currentPage() {
      return this.$store.getters.getCurrentPage;
    },
    getSortByName() {
      return this.$store.getters.getSortByName;
    },
    getSortByLevel() {
      return this.$store.getters.getSortByLevel;
    },
    getSortByEmail() {
      return this.$store.getters.getSortByEmail;
    },
    getSortByLastActivity() {
      return this.$store.getters.getSortByLastActivity;
    },
    lang() {
      return this.$i18n.locale;
    },
    getPermissions() {
      return this.$store.state.auth.user.permissionsFlags;
    },
    isCheckedAllUser() {
      return this.users
        .every((user) => this.getCheckedUsers.some((checkedUser) => checkedUser._id === user._id));
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch('getAllUsers');
    this.loading = false;
  },
  methods: {
    async downloadSelectedUsersCSV() {
      const downloadCSVData = {
        users: this.getCheckedUsers,
        lang: this.lang,
        allUsers: false,
      };
      this.loadingDownloadCSV = true;
      const { data } = await NetworkAdmin.postDownloadUserCSV(downloadCSVData);
      this.downloadCSVFromBlob(data);
    },
    async downloadAllUsersCSV() {
      const downloadCSVData = {
        users: {},
        lang: this.lang,
        allUsers: true,
      };

      this.loadingDownloadCSV = true;
      const { data } = await NetworkAdmin.postDownloadUserCSV(downloadCSVData);
      this.downloadCSVFromBlob(data);
    },
    downloadCSVFromBlob(data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'exportUsers.csv';
      a.style.display = 'none';
      document.body.appendChild(a);

      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      this.loadingDownloadCSV = false;
    },
    async changeUserTableLoadingState(bool) {
      this.loading = bool;
    },
    async deleteCheckedUsers() {
      if (this.getCheckedUsers.length !== 0) {
        this.showPopup = true;
      }
    },
    onPopupCancel() {
      this.showPopup = false;
    },
    async onPopupConfirm() {
      this.showPopup = false;

      const data = {};
      data.userIDs = this.getCheckedUsers.map((item) => item._id);

      let successfulDelete = '';
      if (this.getCheckedUsers.length === 1) {
        successfulDelete = `${this.$t(
          'UserAdministrationComponent.toast.successfulDeleteForOne',
        )} ${this.getCheckedUsers[0].name}`;
      } else {
        successfulDelete = this.$t('UserAdministrationComponent.toast.successfulDelete');
      }

      let errorDelete = '';
      if (this.getCheckedUsers.length === 1) {
        errorDelete = `${this.$t('UserAdministrationComponent.toast.errorDeleteForOne')} ${
          this.getCheckedUsers[0].name
        }`;
      } else {
        errorDelete = this.$t('UserAdministrationComponent.toast.errorDelete');
      }

      try {
        this.showPopup = false;
        await this.$store.dispatch('deleteUser', data);
        await this.$store.dispatch('getAllUsers');
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: successfulDelete,
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: errorDelete,
          type: 'error',
        });
      }
      this.$store.dispatch('uncheckAllUsers');
    },
    updateCurrentPage(currentPage) {
      this.$store.dispatch('updateCurrentPage', currentPage);
      this.$store.dispatch('getAllUsers');
    },
    handleCheckboxChangeAllUser() {
      if (this.isCheckedAllUser) {
        this.users.forEach((user) => {
          this.$store.dispatch('removeCheckedUser', user._id);
        });
      } else {
        this.users.forEach((user) => {
          if (!this.$store.getters.isCheckedUser(user._id)) {
            this.$store.dispatch('addCheckedUser', user);
          }
        });
      }
    },

    async handleSortChange(fieldName, currentValue) {
      let newValue;

      switch (currentValue) {
        case 0:
          newValue = 1;
          break;
        case 1:
          newValue = -1;
          break;
        case -1:
          newValue = 0;
          break;
        default:
          newValue = 0;
      }

      await this.$store.commit('updateStoreState', { fieldName, newValue });
      this.loading = true;
      await this.$store.dispatch('getAllUsers');
      this.loading = false;
    },

    clearCheckedUsers() {
      this.$store.dispatch('uncheckAllUsers');
    },
  },
};
</script>

<style scoped>
/*---------------- GENERAL ------------------*/
.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  max-width: 960px;
  margin: 0 auto;
}

.content-wrapper {
  width: 960px;
}

.loading-img {
  width: 125px;
  height: 125px;
}

@media screen and (max-width: 1400px) {
  .content-wrapper {
    width: 100vw;
  }

  .wrapper {
    width: 90vw;
  }
}

/*---------------- USERS ------------------*/

.loading-container {
  display: flex;
  justify-content: center;
  margin-top: 2em;
}

.level-text {
  padding-right: 7px;
  text-align: center
}

.users-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
}

.users-option-element {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px 0.8rem 5px;
  border-radius: 0.5rem;
  border-radius: var(--card-border-radius);
  background-color: var(--color-light);
}

.user-option-icon {
  margin-right: 7px;
  height: 20px;
}

.users-per-page {
  display: flex;
  align-items: center;
}

.users-per-page-dropdown {
  margin-left: 5px;
}

.users-grid {
  display: grid;
  grid-template-columns: 3% 29% 8% 40% 17%;
  grid-column-gap: 5px;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
  box-sizing: border-box;
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-light);
  overflow-x: auto;
}

@media screen and (max-width: 1250px) {
  .users-grid {
    grid-template-columns: 37px 220px 80px 250px 200px;
    width: 90vw;
  }
}

.users-grid > :nth-child(5n + 4),
.users-grid > :nth-child(5n + 3),
.users-grid > :nth-child(5n + 2) {
  border-right: 1px solid gray;
}

.selected-users-count{
  height: 2rem;
}

.users-grid-columnName{
  display: flex;
  flex-direction: row;
  align-items: center;
    justify-content: space-between;
    padding-right: 10px;
}

/*------------------- CHECKBOX -----------------------*/

.icon-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.checked-icon {
  color: var(--color-orange);
  font-size: 22px;
}

.unchecked-icon {
  font-size: 22px;
}

/*------------ FOOTER ---------------*/
footer {
  color: var(--color-white);
  background-color: var(--color-black);
}

.inner-footer {
  margin: 0 auto;
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.inner-footer > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 300px;
}

.left {
  padding: 1rem 0;
  text-align: left;
}

.left a {
  color: var(--color-white);
  font-weight: 400;
}

.left a:hover {
  color: var(--color-orange);
}

.left #btn_legal-notice {
  margin-right: 3rem;
}

.right {
  padding: 1rem 0;
  text-align: right;
}

.right a {
  color: var(--color-white);
  font-weight: 400;
}

.right a:hover {
  color: var(--color-orange);
}
.delete-button{
  padding-left: 5px;
}
</style>
