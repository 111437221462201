<template>
  <canvas id="satisfactionChart" width="100%" height="100%"></canvas>
  <div class="chart-no-data" v-if="!hasData">
    {{ $t('mngDashboardComponent.notEnoughDataToDisplay') }}
  </div>
</template>
<script>
import { removeTimeToolTipHelper } from '@/helpers/graphPlotHelper';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

export default {
  name: 'EmployeeSatisfactionChartComponent',
  data() {
    return {
      hasData: false,
      satisfactionChart: null,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    // Clean up chart instance if it exists
    if (this.satisfactionChart) {
      this.satisfactionChart.destroy();
    }
  },

  methods: {
    handleResize() {
      if (this.satisfactionChart) {
        this.satisfactionChart.resize();
      }
    },
    // Function to draw a vertical line when hovering over a point
    drawHoverLine(chart, xCoord) {
      const { ctx } = chart;
      const yAxis = chart.scales?.y;

      // Save the current line dash pattern
      const originalLineDash = ctx.getLineDash();
      ctx.setLineDash([5, 5]);
      // Draw the vertical line
      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = '#808080'; // Line color
      ctx.lineWidth = 2; // Line width
      ctx.moveTo(xCoord, yAxis.top);
      ctx.lineTo(xCoord, yAxis.bottom);
      ctx.stroke();
      ctx.restore();

      // Restore the original line dash pattern
      ctx.setLineDash(originalLineDash);
    },
    employeeSatisfactionLineChart(employeeData) {
      // Get the chart container element
      const ctx = document.getElementById('satisfactionChart');
      ctx.parentNode.style.width = '100%';
      ctx.parentNode.style.height = '100%';
      // Check if a chart already exists on this canvas
      if (this.satisfactionChart) {
        // Destroy the existing chart
        this.satisfactionChart.destroy();
      }

      // Extract data from this.employeeSatisfactionData
      const labels = employeeData?.data?.labels ?? [];
      const datasets = employeeData?.data?.datasets ?? [];
      this.hasData = !!datasets.find((s) => s.data.length > 0);
      if (this.hasData === false) return;
      const options = employeeData?.options;
      options.plugins.tooltip = {
        callbacks: {
          title: (ctx1) => removeTimeToolTipHelper(ctx1),
          label(context) {
            return `${context?.dataset?.label}: ${context?.raw?.y.toFixed(2)}`;
          },
        },
      };
      // Create a custom plugin to draw the hover line
      const hoverLinePlugin = {
        beforeDraw: (chart) => {
          const { tooltip, scales } = chart;
          if (tooltip._active && tooltip._active.length && scales) {
            const xCoord = scales?.x.getPixelForValue(tooltip.dataPoints[0].parsed.x);
            this.drawHoverLine(chart, xCoord);
          }
        },
      };

      this.satisfactionChart = new Chart(ctx, {
        type: employeeData?.type,
        data: {
          labels, // Populate with your date labels
          datasets, // Assign your datasets
        },
        options,
        plugins: [hoverLinePlugin],
      });
    },
  },
};
</script>
