<template>
  <div class="home-view">
    <header>
      <header-component/>
    </header>
    <main>
      <router-view/>
    </main>
    <footer>
      <footer-component/>
    </footer>
  </div>
  <push-notification-component/>
</template>

<script>
import FooterComponent from '@/components/Home/Layout/FooterComponent.vue';
import HeaderComponent from '@/components/Home/Layout/HeaderComponent.vue';
import PushNotificationComponent
  from '@/components/Home/Shared/PushNotifications/PushNotificationComponent.vue';
import NetworkAuth from '@/helpers/networking/NetworkAuth';

export default {
  name: 'HomeView',
  components: {
    PushNotificationComponent,
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {
      notificationGraber: null,
      tokenRefresher: null,
    };
  },
  mounted() {
    // setup repeating job for refresh token
    if (process.env.VUE_APP_KEYCLOAK === 'false') {
      this.tokenRefresher = setInterval(async () => {
        const isExpiredToken = NetworkAuth.isTokenExpired(this.$store.state.auth.token, 2000);
        if (isExpiredToken) {
          await this.$store.dispatch('refreshToken');
        }
      }, 1000);
    }
    this.$store.dispatch('getNotifications');
    this.notificationGraber = setInterval(() => {
      // method to be executed;
      this.$store.dispatch('getNotifications');
    }, 5000);
  },
  unmounted() {
    clearInterval(this.notificationGraber);
    clearInterval(this.tokenRefresher);
  },
};
</script>
<style>
*, *::before, *::after {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  border: none;
}

:root {
  /*-----Farben------*/
  /*-----Primary------*/
  --color-orange: #F49718; /*Linkfarbe*/
  --color-black: #1B1B1B; /*Fließtext*/
  --color-dark: #1E2019; /*Überschriften*/
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.98); /*Milchiger Hintergrund*/
  --color-milk: rgba(255, 255, 255, 0.98); /*Menü*/
  /*-----Secondary------*/
  --color-bisque: #FCE5C5;
  --color-deep-champagne: #fad49e;
  --color-mellow-apricot: #f9c277;
  --color-yellow-orange: #f7b150;
  --color-fulvous: #d6810a;
  --color-liver-dogs: #af6a08;
  /*-----System------*/
  --color-disabled: #999999; /*Akzentfarbe*/
  --color-light-grey: #e7e7e7;
  --color-error: #ff948d;
  --color-success: #aeeab7;

  --border-radius: 2rem;
  --card-border-radius: 1rem;
  --card-padding: 1rem;
  --card-box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.1);
  --card-close-btn: 18px;
  /*----- Feedback Layout -----*/
  --feedback-top: 300px;
  --feedback-scroll-margin: 200px;
}

h1 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

h2 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

h3 {
  font-family: "Poppins";
  font-size: 23px;
  font-weight: normal;
  letter-spacing: 0.19px;
  line-height: 32px;
  color: var(--color-dark);
}

h4 {
  padding: 0.5rem 0;
}

p {
  padding: 0.5rem 0;
}

html, body {
  position: relative;
  overflow-x: hidden;
}

.home-view {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  color: var(--color-black);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-image: url(../assets/fffbackground-image-groesser.png);
}

.home-view footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  color: var(--color-white);
  background-color: var(--color-black);
}

.home-view header {
  width: 100%;
  top: 0;
  padding: 0.7rem;
  z-index: 10;
}

.home-view header .inner-header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.photo { /*gilt für alle Fotos*/
  display: block;
  width: 100%;
}

.home-view main {
  display: grid;
  position: relative;
  top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8rem;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
</style>
