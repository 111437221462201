import NetworkContent from '@/helpers/networking/NetworkContent';

export default {
  state: {
    weeklyReport: {
      data: {},
      isRelative: false,
    },
    selections: [],
    collaborationKss: {
      collaborationTrendOverTimeData: [],
      avgCollaborationTrendData: [],
      collaborationTrendText: {},
      teamsOptions: [],
      tagsOptions: [],
      collaborationTrendPeriod: [],
      warning: '',
      maxSelection: 1,
    },
    moodomat: {
      employeeSatisfactionData: [],
      avgEmployeeSatisfactionData: [],
      employeeSatisfactionText: {},
      teamsOptions: [],
      tagsOptions: [],
      employeeSatisfactionPeriod: [],
      warning: '',
      maxSelection: 1,
    },
    omindset: {
      oMindSetCompanyData: [],
      mindsetTipText: {},
      teamsOptions: [],
      tagsOptions: [],
      mindsetPeriod: [],
      warning: '',
      maxSelection: 1,
    },
  },
  getters: {
    getWeeklyReport: (state) => state.weeklyReport,
    getCollaborationKss: (state) => state.collaborationKss,
    getMoodomat: (state) => state.moodomat,
    getOmindset: (state) => state.omindset,
    getSelections: (state) => state.selections,
  },
  mutations: {
    setWeeklyReports(state, weeklyReports) {
      state.weeklyReport.data = weeklyReports;
    },
    setSelections(state, selections) {
      state.selections = selections;
    },
    setIsRelative(state, value) {
      state.weeklyReport.isRelative = value;
    },
    setCollaborationTrendData(state, data) {
      state.collaborationKss.collaborationTrendOverTimeData = data.collaborationTrendOverTimeData;
      state.collaborationKss.avgCollaborationTrendData = data.avgCollaborationTrendData;
      state.collaborationKss.collaborationTrendText = data.collaborationTrendText;
      state.collaborationKss.teamsOptions = data.teamsOptions;
      state.collaborationKss.tagsOptions = data.tagsOptions;
      state.collaborationKss.collaborationTrendPeriod = data.collaborationTrendPeriod;
      state.collaborationKss.warning = data.warning;
      state.collaborationKss.maxSelection = data.maxSelection;
    },
    setEmployeeSatisfactionData(state, data) {
      state.moodomat.employeeSatisfactionData = data.employeeSatisfactionData;
      state.moodomat.avgEmployeeSatisfactionData = data.avgEmployeeSatisfactionData;
      state.moodomat.employeeSatisfactionText = data.employeeSatisfactionText;
      state.moodomat.teamsOptions = data.teamsOptions;
      state.moodomat.tagsOptions = data.tagsOptions;
      state.moodomat.employeeSatisfactionPeriod = data.employeeSatisfactionPeriod;
      state.moodomat.warning = data.warning;
      state.moodomat.maxSelection = data.maxSelection;
    },
    setOmindsetCompanyData(state, data) {
      state.omindset.oMindSetCompanyData = data.oMindSetCompanyData;
      state.omindset.mindsetTipText = data.mindsetTipText;
      state.omindset.teamsOptions = data.teamsOptions;
      state.omindset.tagsOptions = data.tagsOptions;
      state.omindset.mindsetPeriod = data.mindsetPeriod;
      state.omindset.warning = data.warning;
      state.omindset.maxSelection = data.maxSelection;
    },
  },
  actions: {
    async fetchInitialCollaborationTrendData({ commit }, payload) {
      const requestData = {
        contentSections: [
          {
            identifier: 'mgmtDash_kss',
            selections: payload?.selections,
            kssPeriodType: payload?.kssPeriodType,
            avgKssPeriodType: payload?.avgKssPeriodType,
          },
        ],
      };
      const lang = localStorage.getItem('lang');
      const { data: response } = await NetworkContent.getInteractiveContent(requestData);
      const collaborationTrendOverTimeData = response?.content?.contentSections[0]?.plot_kssTrend;
      const avgCollaborationTrendData = response?.content?.contentSections[0]?.plot_avgKssTrend;
      const collaborationTrendText = response?.content?.contentSections[0]?.text_kssTip;
      const teamsOptions = response?.content?.contentSections[0]?.list_kssTeams?.elements?.map(
        (element) => ({
          name: element?.caption[lang],
          identifier: element?.identifier,
          selected: element?.selected,
        }),
      );
      const tagsOptions = response?.content?.contentSections[0]?.list_kssTags?.elements;
      const collaborationTrendPeriod = response?.content
        ?.contentSections[0]?.list_kssPeriods?.elements?.map(
          (element) => ({
            name: element?.caption[lang],
            identifier: element?.identifier,
          }),
        );
      const warning = response?.content?.contentSections[0]?.warning;
      const maxSelection = response?.content?.contentSections[0]?.maxSelection;
      commit('setCollaborationTrendData', {
        collaborationTrendOverTimeData,
        avgCollaborationTrendData,
        collaborationTrendText,
        teamsOptions,
        tagsOptions,
        collaborationTrendPeriod,
        warning,
        maxSelection,
      });
    },
    async fetchInitialEmployeeSatisfactionData({ commit }, payload) {
      const requestData = {
        contentSections: [
          {
            identifier: 'mgmtDash_moodomat',
            selections: payload?.selections,
            employeePeriodType: payload?.employeePeriodType,
            avgEmployeePeriodType: payload?.avgEmployeePeriodType,
          },
        ],
      };
      const lang = localStorage.getItem('lang');
      const { data: response } = await NetworkContent.getInteractiveContent(requestData);
      const employeeSatisfactionData = response?.content
        ?.contentSections[0]?.plot_employeeSatisfaction;
      const avgEmployeeSatisfactionData = response?.content
        ?.contentSections[0]?.plot_avgEmployeeSatisfaction;
      const employeeSatisfactionText = response?.content
        ?.contentSections[0]?.text_employeeSatisfactionTip;
      const teamsOptions = response?.content
        ?.contentSections[0]?.list_employeeSatisfactionTeams?.elements?.map(
          (element) => ({
            name: element?.caption[lang],
            identifier: element?.identifier,
            selected: element?.selected,
          }),
        );
      const tagsOptions = response?.content
        ?.contentSections[0]?.list_employeeSatisfactionTags?.elements;
      const employeeSatisfactionPeriod = response?.content
        ?.contentSections[0]?.list_employeeSatisfactionPeriods?.elements?.map(
          (element) => ({
            name: element?.caption[lang],
            identifier: element?.identifier,
          }),
        );
      const warning = response?.content?.contentSections[0]?.warning;
      const maxSelection = response?.content?.contentSections[0]?.maxSelection;
      commit('setEmployeeSatisfactionData', {
        employeeSatisfactionData,
        avgEmployeeSatisfactionData,
        employeeSatisfactionText,
        teamsOptions,
        tagsOptions,
        employeeSatisfactionPeriod,
        warning,
        maxSelection,
      });
    },
    async fetchInitialMindSetCompanyData({ commit }, payload) {
      const requestData = {
        contentSections: [
          {
            identifier: 'mgmtDash_mindset',
            selections: payload?.selections,
            mindsetPeriodType: payload?.mindsetPeriodType,
          },
        ],
      };
      const lang = localStorage.getItem('lang');
      const { data: response } = await NetworkContent.getInteractiveContent(requestData);
      const oMindSetCompanyData = response?.content?.contentSections[0]?.plot_mindset;
      const mindsetTipText = response?.content?.contentSections[0]?.text_mindsetTip;
      const teamsOptions = response?.content?.contentSections[0]?.list_mindsetTeams?.elements?.map(
        (element) => ({
          name: element?.caption[lang],
          identifier: element?.identifier,
          selected: element?.selected,
        }),
      );
      const tagsOptions = response?.content?.contentSections[0]?.list_mindsetTags?.elements;
      const mindsetPeriod = response?.content
        ?.contentSections[0]?.list_mindsetPeriods?.elements?.map(
          (element) => ({
            name: element?.caption[lang],
            identifier: element?.identifier,
          }),
        );
      const warning = response?.content?.contentSections[0]?.warning;
      const maxSelection = response?.content?.contentSections[0]?.maxSelection;
      commit('setOmindsetCompanyData', {
        oMindSetCompanyData,
        mindsetTipText,
        teamsOptions,
        tagsOptions,
        mindsetPeriod,
        warning,
        maxSelection,
      });
    },
    async fetchInitialWeeklyReport({ commit, state }) {
      const requestData = {
        contentSections: [
          {
            identifier: 'mgmtDash_report',
            csv_selected: true,
            isRelative: state.weeklyReport.isRelative,
          },
        ],
      };
      const { data } = await NetworkContent.getInteractiveContent(requestData);
      commit('setWeeklyReports', data?.content?.contentSections[0]);
    },
  },
};
