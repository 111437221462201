<template>
  <div class="filter-component">
    <BaseFilterComponent
      v-if="ready && modelValue.length < max"
      :allowFilter="allowFilter"
      @saveSelection="saveSelection"
      :deleteFilterOnSave="true"
    />
    <template v-if="ready">
      <BaseFilterComponent
        v-for="selection in modelValue"
        :key="selection.id"
        :allowFilter="allowFilter"
        :selection="selection"
        :deleteFilterOnSave="false"
        @saveSelection="saveSelection"
        @removeSelection="removeSelection"
      />
    </template>
  </div>
</template>

<script>
import BaseFilterComponent from './BaseFilterComponent.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    max: Number,
    allowFilter: Object,
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue'],
  name: 'FilterComponent',
  components: { BaseFilterComponent },
  data() {
    return {
      ready: false,
    };
  },
  computed: {},
  async mounted() {
    await this.$store.dispatch('fetchFilterData');
    this.ready = true;
  },
  methods: {
    saveSelection(selection) {
      const newSelections = [...this.modelValue];

      if (selection.id) {
        const index = newSelections.findIndex((s) => s.id === selection.id);
        if (index !== -1) {
          newSelections[index] = { ...newSelections[index], selection };
        }
      } else {
        const couter = newSelections.length >= 1 ? Number(newSelections[newSelections.length - 1].name.en.split(' ')[1]) : 0;
        newSelections.push({
          id: uuidv4(),
          name: {
            en: `${this.$t('FiltersComponent.selection')} ${couter + 1}`,
            de: `${this.$t('FiltersComponent.selection')} ${couter + 1}`,
          },
          selection,
        });
      }

      this.$emit('update:modelValue', newSelections);
    },
    removeSelection(id) {
      const newSelections = this.modelValue.filter((s) => s.id !== id);
      this.$emit('update:modelValue', newSelections);
    },
  },
};
</script>

<style scoped>
.filter-component {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
</style>
