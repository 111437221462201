<template>
  <div class="overlay-container" v-if="getVisibleOverlays">
    <component :is="getOverlayComponent(getOverlay)"
               :data="getOverlay"/>
  </div>
</template>

<script>
import IntroKssTrackerOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/IntroKssTrackerOverlayComponent.vue';
import FeedbackOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/FeedbackOverlayComponent.vue';
import DevTipLibraryOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/DevTipLibraryOverlayComponent.vue';
import SimilarKssWarningOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/SimilarKssWarningOverlayComponent.vue';
import SuperUserProfileOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/SuperUserProfileOverlayComponent.vue';
import CreateAssessmentCalendarOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/CreateAssessmentCalendarOverlayComponent.vue';
import TaskCreatorOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/TaskCreatorOverlayComponent.vue';
import PersonalGoalFeedbackOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/PersonalGoalFeedbackOverlayComponent.vue';
import TravelBuddyFeedbackOverlayComponent
  from '@/components/Home/Shared/FrontendOverlays/TravelBuddyFeedbackOverlayComponent.vue';
import WorkshopParticipantsOverlayComponent
  from '@/components/Home/WorkshopComponent/WorkshopParticipantsOverlayComponent.vue';

export default {
  name: 'FrontendOverlayComponent',
  components: {
    SimilarKssWarningOverlayComponent,
    IntroKssTrackerOverlayComponent,
    DevTipLibraryOverlayComponent,
    FeedbackOverlayComponent,
    SuperUserProfileOverlayComponent,
    CreateAssessmentCalendarOverlayComponent,
    TaskCreatorOverlayComponent,
    PersonalGoalFeedbackOverlayComponent,
    TravelBuddyFeedbackOverlayComponent,
    WorkshopParticipantsOverlayComponent,
  },
  computed: {
    getVisibleOverlays() {
      return this.$store.getters.visibleFrontendOverlays;
    },
    getOverlay() {
      if (this.$store.getters.getFrontendOverlays) {
        return this.$store.getters.getFrontendOverlays[0];
      }

      return null;
    },
  },
  methods: {
    getOverlayComponent(overlay) {
      switch (overlay.type) {
        case 'introKssTracker':
          return 'IntroKssTrackerOverlayComponent';
        case 'devTipLibrary':
          return 'DevTipLibraryOverlayComponent';
        case 'feedback':
          return 'FeedbackOverlayComponent';
        case 'similarKssTasks':
          return 'SimilarKssWarningOverlayComponent';
        case 'superUserProfile':
          return 'SuperUserProfileOverlayComponent';
        case 'createAssessmentCalendar':
          return 'CreateAssessmentCalendarOverlayComponent';
        case 'taskCreator':
          return 'TaskCreatorOverlayComponent';
        case 'personalGoalFeedback':
          return 'PersonalGoalFeedbackOverlayComponent';
        case 'travelBuddyFeedback':
          return 'TravelBuddyFeedbackOverlayComponent';
        case 'workshopParticipants':
          return 'WorkshopParticipantsOverlayComponent';
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
.overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-top: 5%;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.5);

  ::-webkit-scrollbar{
    width: .5em;
  }

  ::-webkit-scrollbar-track{
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 100vw;
    margin-block: 1.5em;
  }

  ::-webkit-scrollbar-thumb{
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 100vw;
  }
}

@media only screen and (max-width: 768px) {
 .overlay-container {
   position: absolute !important;
  }
}

</style>
