import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faMessage,
  faEye,
  faEyeSlash,
  faArrowLeft,
  faCircleCheck,
  faCheck,
  faAngleDown,
  faPowerOff,
  faArrowRight,
  faCalendar,
  faClock,
  faCircleNotch,
  faCircleHalfStroke,
  faAngleRight,
  faAngleLeft,
  faCirclePlus,
  faPen,
  faBoxArchive,
  faUser,
  faTrashCan,
  faLeftLong,
  faRightLong,
  faPlus,
  faCopy,
  faCrown,
  faXmark,
  faCaretDown,
  faBan,
  faCircleQuestion,
  faSquareCheck,
  faSquare,
  faTriangleExclamation,
  faHandsClapping,
  faChevronDown,
  faChevronUp,
  faStar,
  faCircle,
  faThumbsUp,
  faThumbsDown,
  faAngleUp,
  faUsers,
  faCloudArrowDown,
  faFilter,
  faPhone,
  faAnglesLeft,
  faAngleDoubleLeft,
  faAnglesRight,
  faAngleDoubleRight,
  faCalendarDay,
  faUsersViewfinder,
  faRepeat,
  faBullseye,
  faCertificate,
  faMagnifyingGlass,
  faFlagCheckered,
  faArrowsRotate,
  faSort,
  faSortUp,
  faSortDown,
  faStopwatch,
  faCaretRight,

} from '@fortawesome/free-solid-svg-icons';
import {
  faLightbulb,
  faSquare as farSquare,
  faClock as farClock,
  faHeart,
  faCircleCheck as farCircleCheck,
  faEnvelope,
} from '@fortawesome/free-regular-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  install(app) {
    library.add(faUpRightAndDownLeftFromCenter);
    library.add(faDownLeftAndUpRightToCenter);
    library.add(faStopwatch);
    library.add(faCaretRight);
    library.add(faMessage);
    library.add(faEyeSlash);
    library.add(faEye);
    library.add(faArrowLeft);
    library.add(faCircleCheck);
    library.add(faCheck);
    library.add(faAngleDown);
    library.add(faPowerOff);
    library.add(faArrowRight);
    library.add(faCalendar);
    library.add(faCalendarDay);
    library.add(faClock);
    library.add(faCircleNotch);
    library.add(faCircleHalfStroke);
    library.add(faAngleRight);
    library.add(faAngleLeft);
    library.add(faCirclePlus);
    library.add(faPen);
    library.add(faBoxArchive);
    library.add(faUser);
    library.add(faTrashCan);
    library.add(faLeftLong);
    library.add(faRightLong);
    library.add(faPlus);
    library.add(faCopy);
    library.add(faCrown);
    library.add(faXmark);
    library.add(faCaretDown);
    library.add(faBan);
    library.add(faCircleQuestion);
    library.add(faSquareCheck);
    library.add(faSquare);
    library.add(faTriangleExclamation);
    library.add(faHandsClapping);
    library.add(faChevronDown);
    library.add(faChevronUp);
    library.add(faStar);
    library.add(faLightbulb);
    library.add(faCircle);
    library.add(farSquare);
    library.add(faThumbsUp);
    library.add(faThumbsDown);
    library.add(faAngleUp);
    library.add(farClock);
    library.add(faHeart);
    library.add(farCircleCheck);
    library.add(faMicrosoft);
    library.add(faUsers);
    library.add(farCircleCheck);
    library.add(faCloudArrowDown);
    library.add(faFilter);
    library.add(faEnvelope);
    library.add(faPhone);
    library.add(faAnglesLeft);
    library.add(faAngleDoubleLeft);
    library.add(faAnglesRight);
    library.add(faAngleDoubleRight);
    library.add(faUsersViewfinder);
    library.add(faRepeat);
    library.add(faBullseye);
    library.add(faCertificate);
    library.add(faMagnifyingGlass);
    library.add(faFlagCheckered);
    library.add(faArrowsRotate);
    library.add(faSort);
    library.add(faSortUp);
    library.add(faSortDown);
    app.component('font-awesome-icon', FontAwesomeIcon);
  },
};
