<template>
  <div class="mainCollaborationTrend">
    <div class="upperCollaborationTrend">
      <FiltersComponent
        :allowFilter="{
          team: true,
          tag: true
        }"
        :max="collaborationMaxSelection"
        :multiple="false"
        :modelValue="selections"
        @update:modelValue="saveSelection"
      />
    </div>
    <MessageBoxComponent is-warning :text="collaborationWarning" />
    <div class="lowerCollaborationTrend">
      <div class="leftCollaborationContainer">
        <div class="topCollaborationChartContainer">
          <h1>{{ $t('mngDashboardComponent.collaborationTrendOverTime') }}</h1>
          <select v-model="KSSSelectFilter" @change="applyKSSFilter">
            <option
              v-for="item in collaborationTrendPeriod"
              :key="item?.identifier"
              :value="item?.identifier"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="collaborationChartContainer"
          tabindex="0">
          <CollaborationTrendChart ref="collaborationTrend" />
        </div>
      </div>
      <div class="rightCollaborationContainer">
        <div class="avgChartContainer" v-show="showAverageChart">
          <div class="topAvgCollaborationContainer">
            <h1>{{ $t('mngDashboardComponent.trackerUsage') }}</h1>

            <select v-model="avgKSSSelectFilter" @change="applyAvgKSSFilter">
              <option
                v-for="item in collaborationTrendPeriod"
                :key="item?.identifier"
                :value="item?.identifier"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="avgCollaborationChart">
            <AvgCollaborationTrendChart ref="avgCollaborationTrend" />
          </div>
        </div>
        <div class="collaborationTextContainer">
          <div style="display: flex">
            <img src="@/assets/light.svg" alt="light" />
            <h1>
              {{
                collaborationTrendText?.headline
              }}
            </h1>
          </div>
          <p>
            {{
              collaborationTrendText?.text
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../managementDashboard.css';
import CollaborationTrendChart from '@/components/Home/ManagementDashboard/CollaborationTrendComponent/CollaborationTrendChartComponent.vue';
import AvgCollaborationTrendChart from '@/components/Home/ManagementDashboard/CollaborationTrendComponent/AvgCollaborationChartComponent.vue';
import FiltersComponent from '@/components/Home/ManagementDashboard/common/FiltersComponent.vue';
import MessageBoxComponent from '@/components/Home/ManagementDashboard/common/MessageBoxComponent.vue';

export default {
  name: 'CollaborationTrendComponent',
  data() {
    return {
      KSSSelectFilter: 'Last7Days',
      avgKSSSelectFilter: 'Last7Days',
      showAverageChart: true,
    };
  },
  components: {
    CollaborationTrendChart,
    AvgCollaborationTrendChart,
    FiltersComponent,
    MessageBoxComponent,
  },
  computed: {
    selections: {
      get() {
        return this.$store.getters.getSelections;
      },
      set(value) {
        this.$store.commit('setSelections', value);
      },
    },
    collaborationTrendOverTimeData() {
      return this.$store.getters.getCollaborationKss?.collaborationTrendOverTimeData;
    },
    avgCollaborationTrendData() {
      return this.$store.getters.getCollaborationKss?.avgCollaborationTrendData;
    },
    collaborationTrendText() {
      return this.$store.getters.getCollaborationKss?.collaborationTrendText;
    },
    collaborationTrendPeriod() {
      return this.$store.getters.getCollaborationKss?.collaborationTrendPeriod;
    },
    collaborationMaxSelection() {
      return this.$store.getters.getCollaborationKss?.maxSelection;
    },
    collaborationWarning() {
      return this.$store.getters.getCollaborationKss?.warning;
    },
  },
  methods: {
    // --------- Main Function to call the api on every filters change ---------------- //
    async KSSTrendOverAllFunction(isKSSTrend) {
      try {
        await this.$store.dispatch('fetchInitialCollaborationTrendData', {
          selections: this.selections,
          kssPeriodType: this.KSSSelectFilter,
          avgKssPeriodType: this.avgKSSSelectFilter,
        });
        if (isKSSTrend === 'isKSSTrend') {
          this.$refs.collaborationTrend.collaborationTrendChart(
            this.collaborationTrendOverTimeData,
          );
        } else if (isKSSTrend === 'isAvgKSSTrend') {
          this.$refs.avgCollaborationTrend.avgCollaborationTrendChart(
            this.avgCollaborationTrendData,
          );
        } else {
          this.$refs.collaborationTrend.collaborationTrendChart(
            this.collaborationTrendOverTimeData,
          );
          this.$refs.avgCollaborationTrend.avgCollaborationTrendChart(
            this.avgCollaborationTrendData,
          );
        }
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
      }
    },
    // --------------- Filters---------------- //
    applyKSSFilter() {
      this.KSSTrendOverAllFunction('isKSSTrend');
    },
    applyAvgKSSFilter() {
      this.KSSTrendOverAllFunction('isAvgKSSTrend');
    },
    saveSelection(selection) {
      this.selections = selection;
    },
  },
  watch: {
    selections() {
      this.KSSTrendOverAllFunction('');
    },
  },
  mounted() {
    this.KSSTrendOverAllFunction('');
  },
};
</script>
