export default {
  "LoginComponent": {
    "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready for your mindset development?"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not registered yet?"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "placeholders": {
      "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
    }
  },
  "RegisterComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an account"])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Use"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "termsOfUseAndPrivacy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([" I agree to the ", _interpolate(_list(0)), " and ", _interpolate(_list(1)), "."])},
    "placeholders": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name*"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name*"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address*"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password*"])},
      "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password*"])},
      "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone +49"])}
    },
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])}
  },
  "NewPasswordComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "headingEnterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your new Password here."])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "placeholders": {
      "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new Password*"])},
      "repeatNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat password*"])}
    }
  },
  "SuccessPasswordChangeComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "passwordChangeSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed."])}
  },
  "ActivateAccountComponent": {
    "accountActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account has been successfully activated!"])},
    "accountNotActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account could not be activated. Please reach out to your administrator."])},
    "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to login"])},
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "invalidUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid url"])}
  },
  "PasswordResetComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "resetInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email with further instructions within the next 5 minutes."])},
    "placeholders": {
      "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address*"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please insert email address here."])}
    }
  },
  "AuthTeamComponent": {
    "chooseYourTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your batch"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])}
  },
  "AuthTagSelectComponent": {
    "chooseTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindly choose your tags"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "selectTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the tags that apply to you:"])},
    "pleaseAnswerQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please answer Question: ", _interpolate(_named("question"))])}
  },
  "SuccessEmailResetComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email was sent"])},
    "emailSentMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email was sent to your profile email address."])}
  },
  "SuccessRegistrationComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "registerSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration successful"])},
    "registerInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your registration was successful. You will receive an email in a few minutes to activate your account."])}
  },
  "FooterComponent": {
    "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal notice"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of use"])}
  },
  "HeaderComponent": {
    "oiMindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMindset"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "workshops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshops"])},
    "questionCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question catalog"])},
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Results"])},
    "yourOwnResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own Results"])},
    "teamResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team Results"])},
    "teamspaceSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspace setup"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
    "teamadministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMIN"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "goToProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to profile"])},
    "knowledgeDatabase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge Database"])},
    "omindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMindset"])},
    "mngDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Dashboard"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "personalSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Activities"])},
    "personalGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Goals"])}
  },
  "HomeComponent": {
    "teamspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspace"])},
    "myTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Tasks"])},
    "yourWeekgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Week Goals"])},
    "myPersonalGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Personal Goals"])}
  },
  "PersonalGoalsComponent": {
    "noGoalsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no personal goals set."])},
    "noGoalsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set yourself personal goals to fill this area with content and take your development to the next level."])},
    "newGoalButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Goal"])},
    "showCompletedGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show Completed Goals"])},
    "popup": {
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you would like to delete this goal?"])}
    }
  },
  "WeekgoalComponent": {
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker"])},
    "kssCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS Creator"])},
    "moodomat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood-O-Mat"])},
    "oi_kpi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trust Evaluation"])}
  },
  "TaskComponent": {
    "mindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMindset Evaluation"])},
    "kss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker"])},
    "oi_kpi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Innovation Evaluation"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Evaluation"])},
    "taskBeginsAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task begins at"])},
    "taskDoUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do until"])},
    "taskExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expired at"])},
    "noDeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No deadline"])},
    "Status:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "FiltersComponent": {
    "selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection"])},
    "saveSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save selection"])},
    "compareToAllCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare Organization"])}
  },
  "KssCreatorComponent": {
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
    "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the expected end time of the meeting"])},
    "furtherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add further information"])},
    "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "createKssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])}
  },
  "KssCalendarComponent": {
    "weekdaysShort": {
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])}
    },
    "months": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
    }
  },
  "BreadCrumbs": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker"])},
    "kssCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS Creator"])},
    "personalGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Goals"])},
    "workshops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshops"])},
    "knowledgeDatabase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge Database"])},
    "omindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMindset"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMIN"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard - My Results"])},
    "teamDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard - Team Results"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "Module2OIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module 2 OMindset Evaluation"])},
    "mngDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Dashboard"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "teamadministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
    "useradministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "pauseNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage Pause Notifications"])}
  },
  "mngDashboardComponent": {
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "teams/tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams/Tags"])},
    "notEnoughDataToDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough data to display"])},
    "collaborationTrendOverTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Trend"])},
    "theOMindsetInYourCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The OMindset in your organization"])},
    "avgKssTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg. Collaboration Trend"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention!"])},
    "teamsSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teams selected"])},
    "tagSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag selected"])},
    "yourWeeklyReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Weekly Report"])},
    "relativeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relative Data"])},
    "csvDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV download"])},
    "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "360OMindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360 OMindset"])},
    "collaborationTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker"])},
    "mood-o-mat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood-o-Mat"])},
    "developmentTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development Tips"])},
    "totalUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Users"])},
    "vsLastQuarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vs last quarter"])},
    "totalTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Teams"])},
    "avgTimespent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ time spent this week"])},
    "avgOvertime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ time spent all weeks"])},
    "avgLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ of logins this week"])},
    "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users with login this week"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sent"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["created"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["expired"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["submitted"])},
    "avgSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg. Satisfaction"])},
    "avgChecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg. Checked"])},
    "avgFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg. helpful tips"])},
    "clickToCompareCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare to whole organization"])},
    "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare"])},
    "trackerUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracker Usage"])},
    "avgEmployeeSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average mood"])},
    "employeeSatisfactionOverTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood-O-Mat trend"])}
  },
  "KssTrackerComponent": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
    "alreadyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already created"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search tasks"])},
    "showArchivedTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show archived tasks"])}
  },
  "KssRuntimeComponent": {
    "headingRuntime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the expected end time of the meeting"])},
    "createKssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Collaboration Tracker"])},
    "selectRuntime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm time"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select date"])},
    "startKssTrackerNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note: The start time of the Collaboration Tracker should be the end time of the meeting."])},
    "showArchivedTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show finished tasks"])},
    "myTrackers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My KSS Trackers"])}
  },
  "KssChampionUserListQuestion": {
    "beforeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose "])},
    "afterNumber": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["  more participant."]), _normalize(["more participants."])])},
    "maybeNextTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maybe next time"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose"])}
  },
  "KssFormComponent": {
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "addParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add participants"])},
    "addTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add teams"])},
    "addDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add description (optional)"])},
    "addMeetingSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add meeting subject"])}
  },
  "ExternalUsersInviteComponent": {
    "externalInvites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External invites"])},
    "placeholder": {
      "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Email"])}
    }
  },
  "KssTaskArchivedComponent": {
    "collaborationTracker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collaboration Tracker: ", _interpolate(_named("name"))])},
    "lessParticipants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["less than ", _interpolate(_named("count")), " participants"])}
  },
  "KssTaskCreatedComponent": {
    "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["participant"]), _normalize(["participants"])])},
    "updateTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update task"])},
    "nextEvents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next ", _interpolate(_named("count")), " events:"])},
    "collaborationTracker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Collaboration Tracker: ", _interpolate(_named("name"))])},
    "toast": {
      "successfulDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tracker has been deleted"])},
      "errorDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tracker could not be deleted"])},
      "successfulCancelDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracker successfully cancelled"])},
      "successfulScheduleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracker successfully scheduled"])}
    },
    "popup": {
      "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this task?"])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    }
  },
  "TravelBuddy": {
    "travelBuddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel Buddy"])},
    "markAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as done"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Week"])},
    "skipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skipped"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "userMarkedMeetingAsSkipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting marked as skipped."])},
    "userMarkedMeetingAsCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting marked as completed."])},
    "planMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan a meeting this week with this person"])},
    "meetingInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting Host:"])},
    "meetingArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrive and organize: 'How are you today?'"])},
    "meetingQ1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What challenges do you face currently at work?"])},
    "meetingQ1Tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discuss potential ideas to solve the challenge together"])},
    "meetingQ2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you recently learned something useful or interesting, (i.e. \"Life Hack\")?"])},
    "meetingQ2Tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share and discuss"])},
    "switchRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch roles"])},
    "wrapUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrap up"])},
    "noTravelBuddyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no travel buddy this week."])}
  },
  "PersonalGoals": {
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Goal"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "deleteGoalButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Goal"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "createGoal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Goal"])},
    "qSpecific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is my goal in one sentence?"])},
    "qMeasurable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How will I measure progress and determine when the goal is met?"])},
    "qAchievable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What actions must I take to complete this goal?"])},
    "qRelevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why is achieving this goal important to me and my job and/or organization?"])},
    "qGoalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By when do I want to achieve the goal?"])},
    "qGoalType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of goal is it?"])},
    "qGoalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal name"])},
    "popupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not filled all the information, are you sure you want to continue?"])},
    "selectGoal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a goal type"])},
    "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a goal name"])},
    "incorrectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date must be in the future"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your answer"])},
    "specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific?"])},
    "measurable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurable?"])},
    "achievable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achievable?"])},
    "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevant?"])},
    "goalType": {
      "habit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habit"])},
      "mindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindset"])},
      "reflection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflection"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New goal created"])},
    "updateMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal goal updated"])},
    "toast": {
      "maximumGoalsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of active goals reached."])}
    },
    "desc": {
      "mindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this type of goal when you are aiming to actively work on your mindset and how you approach situations."])},
      "habit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This type is suited for goals with which you wanting to set up a new habit our routine."])},
      "reflection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this type if your goal is to take your time, take a step back and reflect upon something."])}
    }
  },
  "Mindset360CreatorComponent": {
    "furtherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add further information"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "addParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add participants"])},
    "addTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add teams"])},
    "externalInvites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["External invites"])},
    "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send request"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select <span style=\"font-weight: bold; color: var(--color-orange);\">7-10 people</span> people from whom you would like to request a 360° OMindset evaluation about you. Once at least 5 have responded, you will see a result. More information can be found <a href=\"/knowledge-database\">here</a>."])}
  },
  "Mindset360ParticipantsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search participants"])},
      "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No participants"])}
    }
  },
  "TeamAdministrationComponent": {
    "createNewTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New team"])}
  },
  "UserAdministrationComponent": {
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results"])},
    "popup": {
      "deleteMessage": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Are you sure you want to delete this user?"]), _normalize(["Are you sure you want to delete these users?"])])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "toast": {
      "successfulTaskCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The task has been created"])},
      "errorTaskCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The task could not be created"])},
      "successfulDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The users have been deleted"])},
      "errorDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The users could not be deleted"])},
      "successfulDeleteForOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful deletion of user"])},
      "errorDeleteForOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsuccessful deletion of user"])}
    },
    "options": {
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "deleteMarked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete marked"])},
      "createAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create assessment"])},
      "downloadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download CSV"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
      "usersPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users per page"])}
    },
    "columnNames": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
      "lastActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last activity"])}
    },
    "filterOptions": {
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
      "openTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open tasks"])},
      "registrationPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration period"])},
      "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filter"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before"])},
      "workshopParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop participation"])}
    },
    "downloadCSV": {
      "downloadSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Selected Users"])},
      "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download All Users"])}
    }
  },
  "UserAdministrationCreateAssessmentComponent": {
    "customSchemaId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom SchemaID"])},
    "options": {
      "createAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create assessment"])}
    }
  },
  "SuperUserProfileOverlayComponent": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User info"])},
    "lastActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last activity"])},
    "memberSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member since"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "openTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open tasks"])},
    "completedTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed tasks"])},
    "weeklyGoalsStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly goals status"])},
    "workshopParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop participation"])},
    "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
    "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
    "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "weeklyGoals": {
      "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS Tracker"])},
      "kssCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS Creator"])},
      "moodomat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood-o-Mat"])},
      "oi_kpi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Innovation Evaluation"])}
    }
  },
  "LogoutComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])}
  },
  "SingleEditTeamComponent": {
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members: "])},
    "editThisTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit this team"])},
    "editTeamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit team name"])},
    "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users"])},
    "generalPlatformUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General platform user"])},
    "teamLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team leads"])},
    "addToLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to leads"])},
    "addToMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to members"])},
    "removeTeamMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove team member"])},
    "removeTeamlead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove team lead"])},
    "newTeamNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New team"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "saveInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save initially"])},
    "toast": {
      "successfulDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The team has been deleted"])},
      "errorDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The team could not be deleted"])}
    },
    "popup": {
      "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this team?"])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
    },
    "searchPlaceholders": {
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Users"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Members"])},
      "leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Team leads"])}
    },
    "displayTeamspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display in teamspace"])}
  },
  "QuestionnaireComponent": {
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
  },
  "KssChampionQuestion": {
    "kssChampion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS Champ"])}
  },
  "IntegrativeComplexityQuestion": {
    "placeholders": {
      "answerTextbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type answer here..."])}
    }
  },
  "MissionCompleteComponent": {
    "missionCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission completed successfully"])},
    "submittedAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have submitted your answer."])},
    "seeResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])}
  },
  "SliderQuestion": {
    "datalistOptions": {
      "stronglyDisagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strongly disagree"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disagree"])},
      "indifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indifferent"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["agree"])},
      "stronglyAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strongly agree"])}
    }
  },
  "TextQuestion": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your answer"])}
  },
  "RateIntegrativeComplexityValue": {
    "datalistOptions": {
      "notImportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not important"])},
      "lessImportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["less important"])},
      "indifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indifferent"])},
      "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["important"])},
      "stronglyImportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strongly important"])},
      "footlineOMIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform GmbH"])}
    }
  },
  "KssRecurringTaskComponent": {
    "isRecurringTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, this is a recurring task."])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily (working days)"])},
    "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])},
    "repeatEvery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["repeat every"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
    "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weeks"])},
    "daysShort": {
      "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
      "TU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
      "WE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
      "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
      "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
      "SU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])}
    }
  },
  "KssChampNotificationComponent": {
    "congratulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations!"])},
    "youRecievedKssChampAward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have received the KSS Champ award."])}
  },
  "KssParticipantsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search participants"])},
      "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No participants"])}
    }
  },
  "EditKssParticipantsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search participants"])},
      "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No participants"])}
    }
  },
  "KssTeamSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Teams"])},
      "noTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Teams"])}
    }
  },
  "RecommendationNotificationComponent": {
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["read more"])},
    "footlineOMIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform GmbH"])},
    "devTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development tip"])},
    "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "giveFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["give feedback"])}
  },
  "TaskReminderNotificationComponent": {
    "finishIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish it"])},
    "taskiWillExpireInfoMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", please be aware that your task will expire in "])},
    "rightAway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["right away"])},
    "footlineOMIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform GmbH"])},
    "hoursShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hour"])},
    "hoursPlural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hours"])},
    "minutesShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
    "secondsShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sec"])}
  },
  "NewTaskNotificationComponent": {
    "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
    "footlineOMIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform GmbH"])},
    "wantToCheckItOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to check it out?"])},
    "newTaskCreatedForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", a new task has been created for you."])}
  },
  "DashboardComponent": {
    "yourCurrentOMindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current OMindset"])},
    "yourKnowlExchangeHis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Collaboration Trend"])},
    "notEnoughDataAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough data available"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "yourMoodomat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood-O-Mat"])},
    "collaborationTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration"])},
    "defaultErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred"])}
  },
  "DashboardKssHistoryComponent": {
    "kssChampionDataTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your knowledge exchange in the current calendar month"])},
    "positiveFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positive feedback for your active participation"])},
    "kssCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker created"])},
    "kssSubmtted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker successfully submitted"])}
  },
  "TeamDashboardComponent": {
    "omindsetTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMindset of your teams"])},
    "kssTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS of your teams"])},
    "notEnoughDataAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough data available"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "moodomat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood-O-Mat"])},
    "moodomatThisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood-O-Mats in the current week"])},
    "moodomatPrevWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mood-O-Mats in the previous week"])},
    "peoplePrevCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of participants"])},
    "peopleCurrCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of participants"])}
  },
  "CompareOimComponent": {
    "compareWithPastOrTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compare your results"])},
    "notEnoughDataAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not enough data available"])},
    "compareTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compare to"])}
  },
  "NotFoundComponent": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found"])},
    "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homepage"])}
  },
  "EditProfileComponent": {
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "chooseLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your language"])},
    "receiveSmsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
    "receivePushText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push"])},
    "receiveMailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "receiveSmsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications per sms"])},
    "receivePushTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications per push"])},
    "receiveMailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive notifications per email"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile (format: +49...)"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "profileSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile saved"])},
    "errorSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile could not be saved"])},
    "NotificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Settings"])},
    "NotificationSettingsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to receive notifications via:"])},
    "PauseNotificationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to pause notifications on"])},
    "PauseNotificationsWeekendsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekends:<br>Don't send notifications between Fri 6 pm - Mon 6 am"])},
    "PauseNotificationsWorkingDayText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working days (Mon - Fri):<br>Don't send notifications between "])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])}
  },
  "ImageUploadComponent": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "successUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image uploaded!"])},
    "errorUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image could not be uploaded."])}
  },
  "DashboardProfileComponent": {
    "yourMindsetInWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your OMindset in words"])},
    "yourMindset360InWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your mindset from the perspective of your colleagues"])},
    "yourDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your strongest mindset dimensions are:"])},
    "youImprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can improve in these dimensions:"])},
    "developmentTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development tip"])}
  },
  "LevelUpOverlayNoteComponent": {
    "congratulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulation!"])},
    "motivationalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through your commitment, your level increased. <br> Keep up the good work!"])},
    "gotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Got it!"])}
  },
  "TeamSelectionOverlayComponent": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "chooseYourTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindly select your team"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team selection saved!"])}
  },
  "TagSelectionOverlayComponent": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "chooseTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kindly choose your tags"])},
    "selectTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the tags that apply to you:"])},
    "pleaseAnswerQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please answer Question: ", _interpolate(_named("question"))])}
  },
  "IntroKssTrackerOverlayComponent": {
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker"])},
    "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "didntParticipate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I did not attend this meeting."])},
    "mistakeChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I was chosen by mistake."])},
    "days": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])}
    }
  },
  "AssessmentExpiredComponent": {
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The assessment is expired!"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Home"])}
  },
  "TaskCreatorOverlayComponent": {
    "alreadyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindset 360° already requested in the last 6 months"])},
    "createEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create an event"])},
    "chooseEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the event:"])},
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker"])},
    "mindset360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360° OMindset"])},
    "personalGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Goal"])},
    "personalGoalsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of active goals reached"])},
    "workshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop"])}
  },
  "DevTipLibraryOverlayComponent": {
    "yourTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your development tips"])},
    "noTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No development tips"])}
  },
  "DevTipItemContainerComponent": {
    "yourTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your development tips"])},
    "noTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No development tips"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["show more"])}
  },
  "DevTipItemComponent": {
    "recievedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Received at:"])},
    "wasThisTipHelpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was this tip helpful?"])},
    "errorCouldNotFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not submit feedback!"])},
    "errorCouldSetDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not mark tip!"])}
  },
  "FeedbackOverlayComponent": {
    "devTipFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development tip"])},
    "ourTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our tip:"])},
    "yourFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feedback:"])},
    "wasTipHelpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was the tip helpful for you?"])},
    "yesSuper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, super"])},
    "notReally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, not really"])},
    "sendFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send feedback"])},
    "placeholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can type your feedback here..."])},
    "errorCouldNotFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not submit feedback!"])},
    "errorRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error: Feedback is required."])},
    "wasTipCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you finished the tip?"])},
    "notYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, not yet"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
  },
  "TravelBuddyFeedbackOverlayComponent": {
    "yourFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your travel buddy feedback"])},
    "sendFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send feedback"])},
    "howDoYouFeel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflect for yourself: What was your highlight of this meeting?"])},
    "howDoYouFeelSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was there a specific reason for skipping this meeting?"])},
    "placeholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can type your feedback here..."])},
    "errorCouldNotFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not submit feedback!"])},
    "meetingStatusQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was the meeting able to take place?"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting is being skipped."])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
    "provideFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide Feedback"])}
  },
  "PersonalGoalFeedbackOverlayComponent": {
    "yourFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feedback"])},
    "sendFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send feedback"])},
    "howDoYouFeel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do you feel?"])},
    "placeholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can type your feedback here..."])},
    "errorCouldNotFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not submit feedback!"])}
  },
  "SimilarKssWarningOverlayComponent": {
    "similarFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar Collaboration Trackers were found."])},
    "doYouWantToCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you still want to create the Collaboration Tracker?"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "SimilarKssItemComponent": {
    "users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("count")), " participant"]), _normalize([_interpolate(_named("count")), " participants"])])}
  },
  "KnowledgeDatabaseComponent": {
    "allYouNeedToKnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All you need to know about the open innovation mindset (OMindset)"])},
    "TheOMindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The OMindset"])},
    "infotextParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is defined as <i>‘an individual’s values, attitudes, and beliefs that capture an individual’s openness towards KSS inside and outside the organizational boundaries that are used as knowledge structures to make decisions regarding KSS which is critical to OI performance’.</i> <br> (Engelsberger et al., 2021)"])},
    "ThatMeans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That means"])},
    "diagramDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the diagram on your dashboard you can see the results of your OMindset evaluation. Here you can see where your strengths and areas for development lie."])},
    "diagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latest research in this area (Engelsberger et al. 2020) has uncovered the following 6 dimensions that make up an open innovation mindset:"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Openness (Are you open to change and willing to go new ways?)"])},
    "creativ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creativity (Do you sometimes think outside the box and unconventionally?)"])},
    "risk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk tolerance (Are you willing to take a risk to give a promising idea a chance?)"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risk and failure tolerance (Are you willing to give a promising idea a chance and take the risk that it might fail? And can you quickly forgive yourself for mistakes made and learn from them?)"])},
    "toSharing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attitude towards sharing knowledge (Do you like to actively share your knowledge and experiences with others?)"])},
    "toSourcing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attitude towards sourcing knowledge (Do you like to actively approach your colleagues and ask for support when you don't know something yourself or would simply like to have another perspective?)"])},
    "ic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathy (Do you actively put yourself in the perspective of others and try to consider other points of view?)"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are your results what you expected and what you thought they would be?"])},
    "results2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or were you a bit surprised and would you have expected your results differently?"])},
    "resultParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this newfound insight into your strengths and areas for development for self-reflection. There is no right or wrong here. If at first glance you have already achieved very high results, look again specifically at the fields in which you did not score quite as high as in the others. There is still some potential for development for everyone and constant further development in these fields will promote your collaboration and innovation behaviour in the long term."])},
    "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" See this as a basis for reflecting on yourself in your daily work with the help of these 6 dimensions and ask yourself from time to time in your daily work where you could try something new, where you could actively share your knowledge with your colleagues, or where you should put yourself in the perspective of your counterpart more often."])},
    "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remind yourself of your areas of development in everyday life and consciously try to become more open. Be curious to see how your openness to collaboration and innovation develops over time."])},
    "HugoBoss": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUGO BOSS - the new values"])},
      "trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRUST is the overarching value of HUGO BOSS. This is primarily about credibility (communication, competence, integrity), respect (support, collaboration, caring) and fairness (justice, impartiality, equality).<br><br>Trust in your team is essential so that you can count on your team members to consider your interests as well as keep you informed. In a team it is important that everyone can rely on each other and that the word given counts."])},
      "eSpirit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTREPRENEURIAL SPIRIT: An entrepreneur in a company is called an intrapreneur. In other words, an intrapreneur is an employee who is thinking and acting entrepreneurial, bold and drives innovation by developing and implementing new ideas, products or processes that align with the company's goals. Important to this is a mindset to see challenges as opportunities and thereby develop innovative solutions. It is an ability to recognize the potentials in change and proactively seek out new business opportunities or ways to improve existing processes, thereby promoting sustainable growth for the organization."])},
      "personalOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL OWNERSHIP: This value is about taking responsibility, being willing to take risks and constantly looking for new innovative solutions. It is important to see mistakes as an opportunity to develop continuously and, above all, quickly. Successes want to be celebrated - even the small ones. The point is to create an atmosphere of acceptance, tolerance and openness in which all people are respected and their perspectives and experiences are valued. An inclusive environment fosters collaboration, innovation and creativity, as different perspectives and experiences can contribute to making better decisions and finding a broader range of solutions."])},
      "teamMentality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEAM MENTALITY: Together we are stronger! In order to make the best use of our different skills, it is important to share knowledge and experiences with colleagues and to actively ask questions. In doing so, it is important to respect, inspire and empower each other and to lead by example with a lot of courage in all of this. A basic requirement for a successful team mentality is to put yourself in the shoes of others and try to understand the different perspectives and learn from them. There is great value in building global networks and benefitting from the different ways of thinking to create something so powerful together that would never be possible alone."])},
      "youthfulSpirit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOUTHFUL SPIRIT: To be open to new things and not to shy away from change, but to approach it positively and with curiosity. Change is a great opportunity to learn and grow. It is important not to lose the child in oneself, to always remain inquisitive, to be passionate and to have fun. One's own creativity and commitment are cornerstones for being able to think outside the box."])}
    },
    "KXDatabase": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward System"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are different development levels on your OMindset Development Journey. The next level can be unlocked by actively using the platform and achieving the weekly goals. You can find the weekly goals at the top of the home screen. They show you your current status. The status counter is reset every Sunday evening. If a week is missed, the weekly goal can be made up the next week."])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following requirements must be achieved within a week for that week to count towards the level achievement score: <ul><li>Create 2 Collaboration Trackers using the KSS Creator functionality for at least 2 participants.</li><li>Please note: Recurring events only count in the week of creation.</li><li>Complete 3 Collaboration Trackers per week.</li><li>Complete 1 Mood-O-Mat per week.</li></ul>"])},
      "levelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To advance to the second level: <ul><li>Total of 4 fully achieved weekly goals are required. It is not necessary for these weeks to be consecutive.</li><li>The Trust Evaluation needs to be completed. This only needs to be done once in the beginning and once at the end of the first year.</li></ul>"])},
      "levelThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Level 3 applies: <ul><li>In addition to the previous level: a total of 12 complete weekly goals required.</li></ul>"])},
      "levelFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For Level 4 applies: <ul><li>In addition to the previous level: a total of 16 complete weekly goals required.</li></ul>"])}
    }
  },
  "LegalNoticeComponent": {
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal notice"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information in accordance with Section 5 TMG"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact information"])},
    "internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internet address: https://www.omindplatform.com"])},
    "graphics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphics and image sources"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer"])},
    "accountability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountability for content"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The contents of our pages have been created with the utmost care. However, we cannot guarantee the contents’ accuracy, completeness or topicality. According to statutory provisions, we are furthermore responsible for our own content on these web pages. In this matter, please note that we are not obliged to monitor the transmitted or saved information of third parties, or investigate circumstances pointing to illegal activity. Our obligations to remove or block the use of information under generally applicable laws remain unaffected by this as per §§ 8 to 10 of the Telemedia Act (TMG)."])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountability for links"])},
    "responsibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsibility for the content of external links (to web pages of third parties) lies solely with the operators of the linked pages. No violations were evident to us at the time of linking. Should any legal infringement become known to us, we will remove the respective link immediately."])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
    "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our web pages and their contents are subject to German copyright law. Unless expressly permitted by law, every form of utilizing, reproducing or processing works subject to copyright protection on our web pages requires the prior consent of the respective owner of the rights. Individual reproductions of a work are only allowed for private use. The materials from these pages are copyrighted and any unauthorized use may violate copyright laws."])}
  },
  "PrivacyComponent": {
    "txt_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy for OMIND platform"])},
    "txt_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At OMIND platform, accessible from www.omindplatform.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by OMIND platform and how we use it."])},
    "txt_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us."])},
    "txt_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in OMIND platform. This policy is not applicable to any information collected offline or via channels other than this website."])},
    "txt_headline2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent"])},
    "txt_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By using our website, you hereby consent to our Privacy Policy and agree to its terms."])},
    "txt_headline3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information we collect"])},
    "txt_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The personal information that you are asked to provide and the reasons why you are asked to provide it will be made clear to you at the point we ask you to provide your personal information. <br> If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide."])},
    "txt_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you register for an account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number."])},
    "txt_headline4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we use your information"])},
    "txt_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We, use the information we collect in various ways, including to:"])},
    "txt_datenVerwendung": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide, operate, and maintain our website"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve, personalize, and expand our website"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand and analyze how you use our website"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop new products, services, features, and functionality"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send you emails"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find and prevent fraud"])}
    ],
    "txt_logFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Files"])},
    "txt_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this as a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the Online Privacy Policy Generator."])},
    "txt_advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advertising Partners Privacy Policies"])},
    "txt_p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may consult this list to find the Privacy Policy for each of the advertising partners of OMIND platform."])},
    "txt_p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on OMIND consulting, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit."])},
    "txt_p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that OMIND platform has no access to or control over these cookies that are used by third-party advertisers."])},
    "txt_thirdParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Third Party Privacy Policies"])},
    "txt_p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options."])},
    "txt_p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites."])},
    "txt_ccpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCPA Privacy Rights (Do Not Sell My Personal Information)"])},
    "txt_p14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under the CCPA, among other rights, California consumers have the right to:"])},
    "txt_listCCPA": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request that a business delete any personal data about the consumer that a business has collected."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request that a business that sells a consumer’s personal data, not sell the consumer’s personal data."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."])}
    ],
    "headline_gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR Data Protection Rights"])},
    "txt_p15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:"])},
    "gdpr_list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to erasure – You have the right to request that we erase your personal data, under certain conditions."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."])}
    ],
    "headnline_childInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children’s Information"])},
    "txt_p16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity."])},
    "txt_p17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records."])}
  },
  "ToastComponent": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning"])},
    "kss": {
      "selectDateErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a date first"])},
      "selectTimeErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a time first"])},
      "selectSubjectErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a subject first"])},
      "selectParticipantsErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add participants first"])},
      "kssCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker created"])},
      "kssUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaboration Tracker updated"])},
      "selectRecurringDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select at least one day on which the task will be repeated."])},
      "pastDateErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected date is older than 60 minutes"])}
    },
    "mindset360": {
      "mindset360Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindset 360° created"])},
      "mindset360UserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select 7 to 10 users"])}
    },
    "questionnaire": {
      "assessmentSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation submitted"])},
      "answerSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer saved"])},
      "noInputErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose an answer"])}
    },
    "teamManagement": {
      "teamUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team updated"])},
      "teamCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team created"])}
    },
    "workshop": {
      "workshopUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop updated successfully"])},
      "workshopCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop created successfully"])},
      "workshopDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop deleted successfully"])},
      "workshopSendReflection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflection has been sent successfully"])},
      "workshopSendReflectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reflection could not be sent"])},
      "workshopDeletedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The workshop could not be deleted"])},
      "workshopDeletedConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this workshop"])},
      "selectDateErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your date is not correct format"])}
    }
  },
  "VeeValidations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The minimal length should be ", _interpolate(_named("length"))])},
    "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The maximal length should be ", _interpolate(_named("length"))])},
    "alphaSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field should only contain letters"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field should be a valid email"])},
    "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The fields do not match it's values"])},
    "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please agree to the terms to proceed"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number formatted invalid. Valid Example: +49176123456789"])},
    "dependent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid phone number to enable text message notifications"])}
  },
  "OimIntroComponent": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great that you are here and ready to reflect upon your open mindset for innovation and collaboration."])},
    "introContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For companies to successfully move from closed to open innovation, it is important that employees as the key drivers for knowledge exchange are ready for it."])},
    "introContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving from closed to open innovation requires a mindset change and this transformation starts with each and every one individually!"])},
    "introContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This evaluation helps you to understand where your strengths and growths areas lie and what your current Open Innovation Mindset looks like."])},
    "letsStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's start"])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
    "howItWorks1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the evaluation different statements will be shown to you."])},
    "howItWorks2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please take your time to think about your responses and answer in the most honest way possible."])},
    "howItWorks3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can answer by moving the slider to the desired position from"])},
    "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" strongly disagree"])},
    "howItWorks3a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" strongly agree."])},
    "howItWorks4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No one else will see the results or judge you on the outcome - it is only a reflection for yourself."])},
    "howItWorks5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The more honest to yourself you are the more precise your results will be and thus, the more precise you will know where to improve afterwards!"])},
    "definitionHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Open Innovation Mindset"])},
    "definition1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["... is defined as"])},
    "definition2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 'individual's values, attitudes, and beliefs that capture an individual's openness towards KSS (Knowledge Sourcing and Sharing) inside and outside the organizational boundaries that are used as knowledge structures to make decisions regarding KSS which is critical to OI performance.'"])}
  },
  "kss_guideline_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Collaboration Tracker is administered by Outlook, hence all changes have to be done using Outlook. You may only delete the Collaboration Tracker on the platform."])},
  "HomeScreenPrompt": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add shortcut to home screen?"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to home screen"])},
    "iphone": {
      "installTheWebapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install the webapp on your phone: Tap the"])},
      "andThenTap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and then tap \"Add to homescreen\"."])}
    }
  },
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "PauseNotificationComponent": {
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "toast": {
      "successfulCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record has been created"])},
      "errorCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record could not be created"])},
      "requiredStartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select start time first"])},
      "requiredEndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select end time first"])},
      "requiredStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select start date first"])},
      "requiredEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select end date first"])},
      "invalidEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date is invalid"])},
      "invalidStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date is invalid"])},
      "startDateAfterToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date must be after today"])},
      "startDateBeforeEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start date must be before end date"])},
      "successfulDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record has been deleted"])},
      "errorDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record could not be deleted"])},
      "successfulUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record has been updated"])},
      "errorUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This record could not be updated"])}
    },
    "form": {
      "Weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
      "OneTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Time"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
      "oneTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Time"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Date"])},
      "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Time"])},
      "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End Time"])}
    },
    "createPauseNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Pause Notifications"])},
    "popup": {
      "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this record?"])}
    }
  },
  "WorkshopTagsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search tags"])},
      "noTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tags"])}
    }
  },
  "WorkshopComponent": {
    "noWorkshopHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have no workshop"])},
    "newWorkshopButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New workshop"])},
    "Facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitator"])},
    "SendReflection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reflection"])},
    "SendReflectionToAllParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reflection to all participants"])},
    "YouNeedToChooseAtLeastOneParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to choose at least one participant"])},
    "noWorkshopText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crafting engaging workshops to enhance personal growth and skill development"])},
    "dataUpdating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your data is being updated. Please try again later"])}
  },
  "WorkshopFormComponent": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description (optional)"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name (optional)"])},
    "beginDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
    "addParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add participants"])},
    "addTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tags"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "theBeginDateMustBeEarlier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The start date has to be earlier than the end date"])},
    "titleRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a title first"])},
    "addFacilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add facilitator"])},
    "addProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add project name"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "selectParticipantsErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add participants first"])},
    "selectTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a tag first"])}
  },
  "FacilitatorsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search facilitator"])},
      "noFacilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No facilitator"])}
    }
  },
  "ProjectNameSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search project name"])},
      "noProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No project name"])}
    }
  }
}