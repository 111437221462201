<template>
  <font-awesome-icon class='sort-icon' :icon='getIcon' />
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'SortIcon',
  components: {
    FontAwesomeIcon,
  },
  props: {
    sortValue: {
      type: Number,
      required: true,
    },
  },
  computed: {
    getIcon() {
      switch (this.sortValue) {
        case 1:
          return faSortDown;
        case -1:
          return faSortUp;
        default:
          return faSort;
      }
    },
  },
};
</script>

<style>
.sort-icon{
    cursor: pointer;
}
</style>
