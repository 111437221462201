<template>
  <div class="popup-Meeting" ref="popupel">
    <p>{{ $t('IntroKssTrackerOverlayComponent.kssTracker') }}</p>
    <div class="kss-intro-container">
      <h2>{{ this.data.assessment?.name }}</h2>
      <p class="kss-intro-date">{{ date }}</p>

      <div class="button-grid">
        <div>
          <button class="grid-button" :disabled="buttonDisabled" @click.prevent="check">
            {{ $t('IntroKssTrackerOverlayComponent.participate') }}
          </button>
        </div>
      </div>
      <p class="description" v-if="this.data.assessment?.description">
      {{ this.data.assessment.description }}</p>
    </div>
    <div class="creator">
      <div class="creator-grid">
        <img
          :src="getCreatorImage"
          alt="" class="creator-image">
        <div>
          <h3>{{ this.data.assessment?.creator?.name }}</h3>
          <p>{{ $t('IntroKssTrackerOverlayComponent.creator') }}</p>
        </div>
      </div>
      <div class="button-flex">
        <div>
          <button @click.prevent="toggle" class="cancel-button" id="button-list">
            {{ $t('IntroKssTrackerOverlayComponent.cancel') }}
            <font-awesome-icon :icon="getIcon"/>
          </button>
          <ul v-if="dropdownVisible" class="button-flex-dropdown">
            <li>
              <button :disabled="buttonDisabled"
                      @click.prevent="cancelAssesment('notInThisParticularMeeting')">
                {{ $t('IntroKssTrackerOverlayComponent.didntParticipate') }}
              </button>
            </li>
            <li>
              <button @click.prevent="cancelAssesment('notInThisMeeting')">
                {{ $t('IntroKssTrackerOverlayComponent.mistakeChoice') }}
              </button>
            </li>
          </ul>
        </div>
       </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    data: Object,
  },
  name: 'IntroKssTrackerOverlayComponent',
  data() {
    return {
      dropdownVisible: false,
      buttonDisabled: false,
    };
  },
  computed: {
    date() {
      const startDate = new Date(this.data.assessment.date_taskBegin);
      // const endDate = new Date(this.data.assessment.date_taskEnd);
      const day = this.$t(`IntroKssTrackerOverlayComponent.days.${startDate.getDay()}`);
      const month = this.$t(`KssCalendarComponent.months.${startDate.getMonth()}`);
      const startTime = `${(`0${startDate.getHours()}`).slice(-2)}:${(`0${startDate.getMinutes()}`).slice(-2)}`;
      // eslint-disable-next-line max-len
      // const sendTime = `${(`0${endDate.getHours()}`).slice(-2)}:${(`0${endDate.getMinutes()}`).slice(-2)}`;
      return `${day}, ${startDate.getDate()}. ${month} ${startDate.getFullYear()} ${startTime}`;// - ${sendTime}`;
    },
    getIcon() {
      if (this.dropdownVisible) {
        return 'fa-chevron-up';
      }
      return 'fa-chevron-down';
    },
    getCreatorImage() {
      if (this.data.assessment.creator?.picture_profile?.small) {
        return this.data.assessment.creator.picture_profile?.small;
      }
      // eslint-disable-next-line global-require
      return require('@/assets/smiley-profil-bild.png');
    },
  },
  methods: {
    scrollToElement() {
      const el = this.$refs.popupel;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    check() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    toggle() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    async cancelAssesment(type) {
      try {
        this.buttonDisabled = true;
        await this.$store.dispatch('cancelKssTask', {
          assessmentID: this.data.assessment._id,
          reasons: [type],
        });
        this.$router.push('/');
        this.$store.commit('removeFrontendOverlay', this.data.id);
        this.buttonDisabled = false;
      } catch (error) {
        this.buttonDisabled = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
  },
  mounted() {
    setTimeout(() => this.scrollToElement(), 150);
  },
};
</script>

<style scoped>
.popup-Meeting {
  font-size: 14px;
  font-weight: bold;
  background-color: var(--color-milk);
  padding: 20px;
  border-radius: 2em;
  min-height: 400px;
  max-width: 421px;
  max-height: 700px;
  height: max-content;
  overscroll-behavior: contain;
  scroll-margin-top: 50px;
}

.button-grid {
  margin-right: 0em;
  text-align: center;
}

.button-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  text-align: center;
  padding: 1em 1em;
}

.kss-intro-date {
  font-weight: 500;
}
@media(max-width: 350px) {
  .button-flex{
    padding: 1rem 0;
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 640px) {
  .popup-Meeting {
    width: 90%;
  }
}

@media (min-width: 540px) {
  .popup-Meeting {
    font-size: 16px;
  }

  .button-grid {
    margin-right: 2em;
  }
}

.kss-intro-container {
  padding: var(--card-padding);
}

.popup-Meeting h2 {
  color: var(--color-orange);
}

.button-grid {
  display: flex;
  padding: 1.5em;
  align-items: center;
  justify-content: center;
}

.button-grid div {
  position: relative;
}

.button-grid .grid-button {
  text-decoration: none;
  font-size: 20px;
  color: white;
  background-color: var(--color-orange);
  border: 1px solid white;
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
  cursor: pointer;
}

.button-grid .grid-button:hover {
  color: var(--color-black);
  background-color: transparent;
  border: 1px solid var(--color-orange);
}

.creator {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.button-flex .cancel-button {
  text-decoration: none;
  font-size: 20px;
  color: var(--color-black);
  background-color: transparent;
  border: 1px solid var(--color-orange);
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
  cursor: pointer;
}

.button-flex .cancel-button:hover {
  color: white;
  background-color: var(--color-fulvous);
  border: 1px solid white;
}

#button-list {
  display: grid;
  grid-template-columns: 1fr 50px;
  place-items: center;
  justify-items: center;
  padding-right: 0.2em;
}

.button-flex .cancel-button:focus-within img {
  rotate: 180deg;
}

.button-flex .button-flex-dropdown {
  display: block;
  position: absolute;
  /*top: 1.9em;*/ /*Kann noch relevant sein, kurz abchecken lassen*/
  background-color: var(--color-milk);
  border: 1px solid var(--color-orange);
  border-radius: 1.5em;
  width: 15rem;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 170px;
}

.button-flex-dropdown li {
  list-style-type: none;
  padding: .6em 0;
  max-width: inherit;
}

.button-flex-dropdown li button {
  text-decoration: none;
  color: black;
  background: none;
  border: none;
  cursor: pointer;
}

.button-flex-dropdown li:not(:last-child) {
  border-bottom: 1px solid rgb(185, 185, 185);
}

.creator .creator-grid {
  display: grid;
  grid-template-columns: 50px 1fr;
  place-items: center;
  justify-items: center;
}

.creator-image {
  width: 45px;
  height: 45px;
  animation: 1;
  overflow: hidden;
  border-radius: 50%;
}

.creator .creator-grid div {
  text-align: left;
  width: 100%;
  line-height: .7em;
  padding-left: 0.5rem;
}

</style>
