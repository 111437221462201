<template>
  <div v-bind:class="trendValuesClassName">
    <h1>
      {{ mainValue }}
      {{ isRelative || isPercentageValue ? "%" : "" }}
    </h1>
    <p>
      {{ avgText }}
      <span :style="getStyle">
        <img :src="getArrow" alt="arrow" />
        {{ getTrendValue }}%</span
      >
    </p>
  </div>
</template>
<script>
import arrowDownPositive from '@/assets/arrow-down-positive.svg';
import arrowDownNegative from '@/assets/arrow-down-negative.svg';
import arrowUpPositive from '@/assets/arrow-up-positive.svg';
import arrowUpNegative from '@/assets/arrow-up-negative.svg';

import './weeklyReport.css';

export default {
  name: 'TrendValuesComponent',
  props: {
    mainValue: [Number, String],
    reverse: Boolean,
    trendValue: Number,
    trendValuesClassName: String,
    avgText: String,
    isRelative: Boolean,
    isPercentageValue: Boolean,
  },
  computed: {
    isNegative() {
      return this.trendValue == null || this.trendValue <= 0;
    },
    getStyle() {
      if (this.reverse === true) {
        return { color: this.isNegative ? '' : '#f49718' };
      }
      return { color: this.isNegative ? '#f49718' : '' };
    },
    getTrendValue() {
      return this.trendValue;
    },
    getArrow() {
      if (this.reverse === true) {
        return this.isNegative ? arrowDownPositive : arrowUpNegative;
      }
      return this.isNegative ? arrowDownNegative : arrowUpPositive;
    },
  },
};
</script>
