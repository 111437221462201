<template>
  <div class="popup-feedback" ref="goalel">
    <button class="close-btn" @click="closePopup">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
    <div class="feedback-grid">
      <font-awesome-icon class="lightbulb-icon" icon="fa-regular fa-lightbulb"/>
      <div>
        <p><b>{{ $t('PersonalGoalFeedbackOverlayComponent.yourFeedback') }}</b></p>
      </div>
    </div>
    <div class="container">
      <form action="#">
        <div class="feedback-grid2">
          <p class="subheadings">{{ $t('PersonalGoalFeedbackOverlayComponent.howDoYouFeel') }}</p>
        </div>
        <textarea v-model="text"
          :placeholder="$t('PersonalGoalFeedbackOverlayComponent.placeholderText')"/>
        <div class="feedback-grid3">
          <button type="button" class="grid-button-submit" :disabled="buttonDisabled"
                  @click.prevent="submitFeedback">
            {{ $t('PersonalGoalFeedbackOverlayComponent.sendFeedback') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalGoalFeedbackOverlayComponent',
  props: {
    data: Object,
  },
  data() {
    return {
      text: '',
      buttonDisabled: false,
    };
  },
  mounted() {
    this.scrollToElement();
  },
  methods: {
    async submitFeedback() {
      try {
        this.buttonDisabled = true;
        await this.$store.dispatch('submitPersonalGoalFeedback', {
          id: this.data.goalId,
          feedback_text: this.text.trim(),
        });
        this.buttonDisabled = false;
        this.$store.commit('removeFrontendOverlay', this.data.id);
      } catch (error) {
        this.buttonDisabled = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('PersonalGoalFeedbackOverlayComponent.errorCouldNotFeedback'),
          type: 'error',
        });
      }
    },
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    scrollToElement() {
      const el = this.$refs.goalel;
      if (el && typeof el.scrollIntoView === 'function') {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

  <style scoped>

  .popup-feedback {
    color: white;
    font-size: 14px;
    position: absolute;
    top: var(--feedback-top);
    left: 50%;
    transform: translate(-50%, -50%); /* Add transform to center the popup */
    margin-right: 5%;
    background-color: rgb(0, 0, 0);
    padding: 1em 3em;
    border-radius: var(--card-border-radius);
    line-height: 1.5em;
    scroll-margin-top: var(--feedback-scroll-margin)
  }

  .popup-feedback .container {
    padding-left: 3em;
  }

  .popup-feedback .container p{
    padding-left: 0.5rem;
    padding-right: 3rem;
    text-align: justify;
  }

  .lightbulb-icon {
    font-size: 32px;
    color: #fdd835;
    text-shadow: 0 0 10px #fdd835;
  }

  .feedback-grid3 {
      padding-right: 3.5rem;
  }

  .feedback-grid2 button.grid-button,
  .feedback-grid3 button.grid-button-submit {
    cursor: pointer;
    text-decoration: none;
    color: #1B1B1B;                               /*color: white;*/
    background: white none;                       /*background: #F49718 none;*/
    border: 1px solid var(--color-orange);        /*border: 1px solid #1E2019;*/
    padding: .5em 1.5em .5em 1.5em;
    border-radius: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .feedback-grid2 button.grid-button > svg,
  .feedback-grid3 button.grid-button-submit > svg {
    margin-right: 8px;
  }

  .feedback-grid2 button.grid-button:hover,
  .feedback-grid3 button.grid-button-submit:hover {
    text-decoration: none;
    color: white;                                  /*color: #1B1B1B;*/
    background-color: #F49718;                      /*background-color: white;*/
    border: 1px solid #1E2019;                     /*border: 1px solid var(--color-orange);*/
    padding: .5em 1.5em .5em 1.5em;
    border-radius: 2em;
  }

  .feedback-grid2 button.grid-button:focus,
  .feedback-grid3 button.grid-button-submit:focus {
    outline: none;
  }

  .subheadings {
    width: 40% !important;
    white-space: nowrap;
  }

  .grid-button-submit:disabled, .grid-button-submit:disabled:hover, .grid-button:disabled:hover  {
    opacity: 0.4;
    background: var(--color-disabled) !important;
  }

  .container textarea {
    resize: vertical;
  }

  @media only screen and (min-width: 1056px) {
    .popup-feedback {
      font-size: 16px;
      left: 50%;
      width: 60%;
      max-width: 90vh;
    }

    .grid-button, .grid-button-submit {
      width: auto;
    }
    .feedback-grid3 {
      padding-right: 2.5rem;
    }

    .popup-feedback .container{
      padding-top: 0.5rem;
      padding-left: 0rem;
     }
    .popup-feedback .container p{
      padding-left: 0.5rem;
      padding-right: 2.5rem;
      text-align: justify;
     }

     .subheadings {
      width: 100% !important;
     }
  }

  @media only screen and (max-width: 1055px) {

    .popup-feedback {
      font-size: 16px;
      left: 50%;
      width: 100%;
      max-width: 90vh;
      padding: 0.5rem 1.5rem 1.5rem 1.5rem;
      position: absolute !important;
    }

    .popup-feedback b {
      line-height: 20px;
    }

    .popup-feedback .container{
      padding-top: 0.5rem;
      padding-left: 0rem;
     }
    .popup-feedback .container p{
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-align: justify;
     }

     .subheadings {
      width: 100% !important;
     }

     .popup-feedback form textarea {
      width: 98%;
     }

  .feedback-grid {
    margin-left: -1em !important;
  }
  }

  .feedback-grid {
    display: grid;
    margin-left: -2em;
    grid-template-columns: 50px 1fr;
    place-items: center;
    justify-items: center;
  }

  .feedback-grid div {
    text-align: left;
    width: 100%;
    line-height: .7em;
  }

  .feedback-grid2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5em;
    place-items: center;
    justify-items: center;
    padding: 0 3em 1.5em 0em;
  }

  @media only screen and (min-width: 1080px) {
    .feedback-grid2 {
      display: flex; /* Change from grid to flex */
      justify-content: flex-start; /* Add space between the two columns */
      align-items: center; /* Vertically align items */
    }

    .subheadings {
      width: 45% !important;
    }

  }

  #feedback-grid2 {
    padding: 0;
  }

  #grid-button2 img {
    rotate: 180deg;
  }

  .feedback-grid3 {
    display: grid;
    place-items: center;
    justify-items: right;
  }

  .feedback-grid3 .grid-button-submit {
    text-decoration: none;
    color: #1B1B1B;                               /*color: #1B1B1B;*/
    background: white none;                       /*background: #F49718;*/
    border: 1px solid var(--color-orange);        /*border: 1px solid #1B1B1B;*/
    padding: .5em 1.5em .5em 1.5em;
    border-radius: 2em;
    display: inline-flex;
    align-items: center;
    width: auto;
    max-width: 150px;
  }

  .popup-feedback form textarea {
    width: 100%;
    height: 6.5em;
    border: none;
    outline: none;
    color: #000;
    font-size: 16px;
    margin-bottom: 1em;
    margin-top: -.5em;
    border-radius: 2em;
    padding: 1em;
  }

  .popup-feedback form textarea:disabled {
    background-color: rgba(219, 219, 219, 0.9);
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 24px;
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    color: var(--color-milk);
  }

  .close-btn:hover {
    color: var(--color-orange);
  }

  .feedback-grid2 {
    padding: 0 3em 1.5em 3em;
  }

  @media screen and (max-width: 421px) {
    .feedback-grid2 {
      padding: 0 3em 1.5em 0;
    }
  }
  </style>
